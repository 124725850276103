import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

interface HorizontalTabsProps {
  selected: string;
}

const HorizontalTabs: FC<HorizontalTabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const SECTIONS = [
    {
      name: 'Année fiscale',
      href: 'settings-accountant/fiscal-year',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'Export',
      href: 'settings-accountant/',
      permission: 'ACCOUNTANT',
    },
    // {
    //   name: 'Plan',
    //   href: 'cash-flow-statement',
    //   permission: 'ACCOUNTANT',
    // },
  ] as const;
  return (
    <div className="w-full">
      <div className="flex flex-col">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ACCOUNTANT' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'text-sm font-normal px-4 py-4 mb-2 w-full text-start rounded-xl',
                  name === selected
                    ? 'text-[#0756F2] bg-[#E6EEFE] z-20'
                    : 'text-[#5C6B7A] cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/accounting/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default HorizontalTabs;
