import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const Home: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3999 9.24378C2.3999 8.84976 2.60045 8.4801 2.93811 8.25176L11.2581 2.62524C11.7022 2.32491 12.2976 2.32491 12.7417 2.62524L21.0617 8.25176C21.3994 8.48011 21.5999 8.84976 21.5999 9.24378V19.7741C21.5999 20.7825 20.7403 21.6 19.6799 21.6H4.3199C3.25952 21.6 2.3999 20.7825 2.3999 19.7741V9.24378Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Home;
