import { Download } from '@/assets/icons';
import { Button } from '@/components/global';
import { useAppTranslation } from '@/hooks';

import { FinancialTabs } from '.';
import { Tabs } from '../common';
import Accordion from './Accordion';

const FinancialStatement = () => {
  const { t } = useAppTranslation();
  const items = [
    {
      title: 'Accordion Item 1',
      amount1: '123 000,000',
      amount2: '456 000,000',
      content: 'Actifs immobilisés',
    },
  ];
  return (
    <section className="grid grid-cols-12 gap-4 p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-screen overflow-y-scroll bg-white">
      <Tabs selected="FINANCIAL_STATEMENT" />
      <div className="col-span-12 flex justify-between">
        <h2 className="text-2xl font-semibold mb-6">
          {t('Financial Statement')}{' '}
        </h2>
      </div>
      <div className="grid grid-cols-12 gap-4 col-span-8 rounded-xl border border-[#C8CFD5] p-4">
        <div className="col-span-12">
          <div className="flex justify-between mb-6">
            <div className="w-2/3">
              <FinancialTabs selected="Balance sheet" />
            </div>

            <Button
              label="Télécharger PDF"
              icon={<Download width={16} height={16} strokeColor="#fff" />}
            />
          </div>
          <div className="flex justify-between mb-6">
            <h3 className="text-xl font-semibold flex items-center">
              Balance sheet: Actifs
            </h3>

            <div className="flex bg-sec text-[#8D98AF] items-center gap-1 p-1 rounded-lg">
              <button className="text-sm cursor-default p-2 text-[#171725] bg-[#F8FAFB] !drop-shadow-[0px_2px_4px_rgba(125,125,125,0.15)] rounded-md">
                Actifs
              </button>
              <button className="text-sm p-2 cursor-pointer hover:opacity-80 transition-opacity duration-20 cursor-pointer rounded-md">
                Capitaux propres
              </button>
            </div>
          </div>
          <div className="col-span-12 flex justify-between items-center w-full h-fit p-2.5 text-left bg-[#E6EEFE] rounded-lg mb-6">
            <div className="flex flex-col items-start mr-4">
              <span className="text-xs text-gray-500">Label</span>
              <span className="font-semibold text-sm">Total actifs:</span>
            </div>
            <div className="flex gap-12 items-center">
              <div className="flex flex-col items-start mr-4">
                <span className="text-xs text-gray-500">2023</span>
                <span className="font-semibold text-sm">123 000,000</span>
              </div>
              <div className="flex flex-col items-start mr-4">
                <span className="text-xs text-gray-500">2022</span>
                <span className="font-semibold text-sm">456 000,000</span>
              </div>
            </div>
          </div>
          <Accordion items={items} />
          <Accordion items={items} />
          <Accordion items={items} />
          <Accordion items={items} />
        </div>
      </div>
      <div className="col-span-4 rounded-xl border border-[#C8CFD5] p-4 h-screen">
        <h3 className="text-xl font-semibold flex items-center mb-6">Note</h3>
        <table className="table-auto w-full">
          <thead className="border-b border-b-[#C8CFD5] h-10">
            <tr>
              <th className="w-[339px] text-left text-sm font-normal text-[#5C6B7A]">
                Label
              </th>
              <th className="w-[122px] text-left text-sm font-normal text-[#5C6B7A]">
                2023
              </th>
              <th className="w-[122px] text-left text-sm font-normal text-[#5C6B7A]">
                2022
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-b-[#C8CFD5] h-10">
              <td className="text-sm font-bold">Immobilisation corporelle</td>
              <td className="text-sm font-bold">2 628,049</td>
              <td className="text-sm font-bold">0 000</td>
            </tr>
            <tr className="border-b border-b-[#C8CFD5] h-10">
              <td className="text-sm font-normal">228200 Equipement bureau</td>
              <td className="text-sm font-normal">2 628,049</td>
              <td className="text-sm font-normal">0 000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default FinancialStatement;
