import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ChevronDown } from '@/assets/icons';
import { plus } from '@/assets/images/dashboard';
import { LoadingSpinner } from '@/components/global';
import {
  useAppComponentVisible,
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import { CompanyService, type FiscalYear, OperationsService } from '@/services';
import {
  setCompanySubscription,
  setFiscalYear,
} from '@/store/actions/action-creators';

import styles from '../Dashboard/Dashboard.module.scss';

interface CompanyProps {}

interface CompanyType {
  name: string;
  logoUrl: string;
  taxId: string;
  id: string;
}

enum GetCompaniesStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum SwitchCompanyStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

const Company: FC<CompanyProps> = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const dispatch = useAppDispatch();

  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);
  const [userCompanies, setUserCompanies] = useState<CompanyType[]>([]);

  const [fiscalYearValue, setFiscalYearValue] = useState<number>(fiscalYear);
  const [years, setYears] = useState<FiscalYear[]>([]);

  const [getCompaniesStatus, setGetCompaniesStatus] = useState(
    GetCompaniesStatus.INITIAL
  );
  const [selectedCompany, setSelectedCompany] = useState<CompanyType>(
    null as unknown as CompanyType
  );
  const [switchCompanyStatus, setSwitchCompanyStatus] = useState(
    SwitchCompanyStatus.INITIAL
  );

  const setDefaultYear = (data: FiscalYear[], year: number) => {
    if (
      !data.find(
        (e) =>
          e.fiscalYear === year ||
          e.fiscalYear === Number(localStorage.getItem('fiscalYear'))
      )
    ) {
      setFiscalYearValue(data[0].fiscalYear);
      dispatch(setFiscalYear(data[0].fiscalYear));
      localStorage.setItem('fiscalYear', data[0].fiscalYear.toString());
    }
  };

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  const getAllCompanies = (signal?: AbortSignal) => {
    setGetCompaniesStatus(GetCompaniesStatus.IN_PROGRESS);

    CompanyService.getUserCompanies(signal)
      .then(({ data }) => {
        data.map((company: CompanyType) => {
          if (company.id === connectedUser.companyId) {
            setSelectedCompany(company);
          }
        });

        setGetCompaniesStatus(GetCompaniesStatus.SUCCESS);
        setUserCompanies(data);
      })
      .catch(() => {
        setGetCompaniesStatus(GetCompaniesStatus.ERROR);
      });
  };

  const getFiscalYears = () => {
    const yearsFound = localStorage.getItem('fiscalYears')
      ? JSON.parse(localStorage.getItem('fiscalYears') ?? '')
      : '';
    if (yearsFound === '') {
      OperationsService.getFiscalYears().then((res) => {
        localStorage.setItem('fiscalYears', JSON.stringify(res.data));
        const data: FiscalYear[] = res.data.sort(
          (a: FiscalYear, b: FiscalYear) => b.fiscalYear - a.fiscalYear
        );
        setYears(data);
        setDefaultYear(data, fiscalYear);
      });
    } else {
      const data: FiscalYear[] = yearsFound.sort(
        (a: FiscalYear, b: FiscalYear) => b.fiscalYear - a.fiscalYear
      );
      setYears(data);
      setDefaultYear(data, fiscalYear);
    }
  };

  useEffect(() => {
    if (!connectedUser.companyId) return;

    const controller = new AbortController();

    getAllCompanies(controller.signal);

    getFiscalYears();

    return () => controller.abort();
  }, [connectedUser.companyId, fiscalYear]);

  const switchCompany = (company: CompanyType) => {
    setSwitchCompanyStatus(SwitchCompanyStatus.IN_PROGRESS);
    CompanyService.switchCompany(company.id)
      .then(({ data }) => {
        setSwitchCompanyStatus(SwitchCompanyStatus.SUCCESS);
        setSelectedCompany(company);
        const accessToken = data.accessToken;
        localStorage.setItem('access-token', accessToken);
        navigate('/dashboard');
        localStorage.removeItem('fiscalYears');
        CompanyService.getCompanySubscription()
          .then((companySub) => {
            dispatch(setCompanySubscription(companySub.data));
          })
          .catch(() => setSwitchCompanyStatus(SwitchCompanyStatus.ERROR));

        window.location.reload();
      })
      .catch(() => {
        setSwitchCompanyStatus(SwitchCompanyStatus.ERROR);
      });
  };

  return (
    <div className={styles.company}>
      <div
        className="flex justify-center items-center h-8 py-2.5 px-4 rounded-[8px] border-[1px] border-[#C8CFD5] focus:outline-none focus:ring focus:ring-[#303840] active:border-[#C8CFD5]"
        onClick={handleToggleMenu}
        onKeyDown={handleToggleMenu}
      >
        {getCompaniesStatus === GetCompaniesStatus.IN_PROGRESS ? (
          <div className="flex items-center gap-2 justify-center w-full h-[50px]">
            <LoadingSpinner width="20" strokeWidth="2" strokeColor="#5C6B7A" />
            <span className="text-[##5C6B7A] text-xs font-semibold">
              {t('Loading')}
            </span>
          </div>
        ) : (
          <div className="flex w-full justify-between max-w-[250px] max-h-[200px] cursor-pointer">
            <span className="text-[#303840] text-xs font-semibold">
              {`${selectedCompany?.name} | ${fiscalYear}`}
            </span>

            <ChevronDown width={16} height={16} strokeColor="#303840" />
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          onClick={(e) => {
            e.cancelable = true;
            e.stopPropagation();
          }}
          className={classNames(styles.menu, 'max-h-[300px] min-w-[250px]')}
        >
          {/* //! Keep it just in case */}
          {/* <div className={styles.inputContainer}>
            <img src={search} alt="Search" />
            <input
              placeholder="Company name"
              onChange={debounceSearchQueryChange}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div> */}

          <div className="flex flex-col gap-3">
            <div className="flex flex-col justify-start gap-1">
              <span className="text-[##5C6B7A] text-xs font-semibold">
                {t('COMPANY')}
              </span>

              <select
                onChange={(e) => {
                  const selectedCompanyName = e.target.value;
                  const selectedCompany = userCompanies.find(
                    (company) => company.name === selectedCompanyName
                  );

                  if (selectedCompany) {
                    switchCompany(selectedCompany);
                  }
                }}
                value={selectedCompany.name}
                className="p-2.5 rounded-md border-[#8D9CAA] outline-none height-5 text-[#303840] text-xs font-semibold"
              >
                {userCompanies.map((company) => (
                  <option
                    disabled={
                      company.id === connectedUser.companyId ||
                      switchCompanyStatus === SwitchCompanyStatus.IN_PROGRESS
                    }
                    key={company.name}
                    onChange={() => {
                      switchCompany(company);
                    }}
                  >
                    {company.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col justify-start gap-1">
              <span className="text-[##5C6B7A] text-xs font-semibold">
                {t('Fiscal year')}
              </span>

              <select
                onFocus={getFiscalYears}
                onChange={(e) => {
                  const selectedValue = (e.target as HTMLSelectElement).value;
                  setFiscalYearValue(Number(selectedValue));
                  dispatch(setFiscalYear(Number(selectedValue)));
                  localStorage.setItem('fiscalYear', selectedValue);
                }}
                value={fiscalYearValue}
                className="p-2.5 rounded-md border-[#8D9CAA] outline-none height-5 text-[#303840] text-xs font-semibold"
              >
                {years.map((year) => (
                  <option key={year.fiscalYear} value={year.fiscalYear}>
                    {year.fiscalYear}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            onClick={() => navigate('/wizard/company-info')}
            className="w-full flex justify-center items-center gap-1 bg-[#2b9f93] p-3 rounded-md text-white text-xs font-semibold mt-2"
          >
            <img className="h-[12px]" src={plus} alt="Add" />
            {t('Add a new company')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Company;
