import { type DeclarationFormType } from '@/lib/forms';

import declarationAPI from './apis/declarationAPI';

export default class DeclarationService {
  static getDeclarationsTemplatesByYear(year: number, signal?: AbortSignal) {
    const params = {
      year: year,
    };
    return declarationAPI.get(`/v1/templates`, {
      signal,
      params,
    });
  }

  static getDeclarationSubmitsByYear(year: number, signal?: AbortSignal) {
    const params = {
      year: year,
    };
    return declarationAPI.get(`/v1/submits`, {
      signal,
      params,
    });
  }

  static getDeclarationTemplateById(id: string, signal?: AbortSignal) {
    return declarationAPI.get(`/v1/templates/${id}`, {
      signal,
    });
  }

  static getAccountantsCompaniesDeclarations(
    year: number,
    signal?: AbortSignal
  ) {
    const params = {
      year: year,
    };
    return declarationAPI.get(`/v1/submits/companies-declarations/accountant`, {
      signal,
      params,
    });
  }

  static createDeclaration(
    companyId: string,
    templateId: string,
    signal?: AbortSignal
  ) {
    const payload = {
      companyId,
      templateId,
    };
    return declarationAPI.post(`/v1/submits`, payload, {
      signal,
    });
  }
  static updateDeclaration(
    submitId: string,
    payload: { note: string; amount: number },
    signal?: AbortSignal
  ) {
    return declarationAPI.put(`/v1/submits/${submitId}`, payload, {
      signal,
    });
  }

  static declare(
    submitId: string,
    payload: { date: Date },
    signal?: AbortSignal
  ) {
    return declarationAPI.patch(`/v1/submits/${submitId}/declare`, payload, {
      signal,
    });
  }

  static undeclare(submitId: string, signal?: AbortSignal) {
    return declarationAPI.patch(`/v1/submits/${submitId}/undeclare`, {
      signal,
    });
  }

  static pay(submitId: string, payload: { date: Date }, signal?: AbortSignal) {
    return declarationAPI.patch(`/v1/submits/${submitId}/pay`, payload, {
      signal,
    });
  }

  static unpay(submitId: string, signal?: AbortSignal) {
    return declarationAPI.patch(`/v1/submits/${submitId}/unpay`, {
      signal,
    });
  }

  static uploadAttachment(submitId: string, file: any, signal?: AbortSignal) {
    const formData = new FormData();
    formData.append('attachment', file);

    return declarationAPI.post(
      `/v1/submits/${submitId}/attachments`,
      formData,
      {
        signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  static downloadAttachment(
    submitId: string,
    attachmentId: string,
    signal?: AbortSignal
  ) {
    return declarationAPI.get(
      `/v1/submits/${submitId}/attachments/${attachmentId}`,
      {
        signal,
      }
    );
  }

  static deleteAttachment(
    submitId: string,
    attachmentId: string,
    signal?: AbortSignal
  ) {
    return declarationAPI.delete(
      `/v1/submits/${submitId}/attachments/${attachmentId}`,
      {
        signal,
      }
    );
  }

  static cancelDeclaration(submitId: string, signal?: AbortController) {
    return declarationAPI.patch(`/v1/submits/${submitId}/cancel`, {
      signal,
    });
  }

  static unCancelDeclaration(submitId: string, signal?: AbortController) {
    return declarationAPI.patch(`/v1/submits/${submitId}/uncancel`, {
      signal,
    });
  }

  static getDeclarationConfig(fiscalYear: number, signal?: AbortSignal) {
    const params = {
      fiscalYear: fiscalYear,
    };
    return declarationAPI.get('/v1/config', {
      signal,
      params,
    });
  }

  static updateDeclarationConfig(
    config: DeclarationFormType,
    signal?: AbortSignal
  ) {
    return declarationAPI.put('/v1/config', config, {
      signal,
    });
  }

  static createDeclarationConfig(
    config: DeclarationFormType,
    signal?: AbortSignal
  ) {
    return declarationAPI.post('/v1/config', config, {
      signal,
    });
  }

  static validateDeclarationConfig(fiscalYear: number, signal?: AbortSignal) {
    return declarationAPI.patch('/v1/config/submit', null, {
      params: {
        fiscalYear,
      },
      signal,
    });
  }

  static getDeclarationsList(fiscalYear: number, signal?: AbortSignal) {
    const params = {
      fiscalYear: fiscalYear,
    };
    return declarationAPI.get('/v1/declaration/all', {
      signal,
      params,
    });
  }

  static getDeclaration(
    fiscalYear: number,
    month: number,
    signal?: AbortSignal
  ) {
    const params = {
      fiscalYear: fiscalYear,
      month: month,
    };
    return declarationAPI.get('/v1/declaration', {
      signal,
      params,
    });
  }

  static submitDeclaration(
    fiscalYear: number,
    month: number,
    signal?: AbortSignal
  ) {
    return declarationAPI.post(
      `/v1/declaration?fiscalYear=${fiscalYear}&month=${month}`,
      {
        signal,
      }
    );
  }
}
