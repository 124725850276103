import { useEffect, useMemo, useState } from 'react';

import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ArrowSwitchHorizontal,
  ArrowUp,
  Bank,
  BarGroup,
  ChevronDouble,
  FileCheck,
  FileEdit,
  FileMultiple,
  FolderOpen,
  MessageChat,
  Plus,
  ReceiptLines,
  Settings,
} from '@/assets/icons';
import { UveyLogo } from '@/assets/images';
import { RegularUveyIcon } from '@/assets/images/dashboard';
import { Button } from '@/components/global';
// import LangSelector from '@/components/global/Dropdown';
import { useAppDispatch, useAppSelector, useAppTranslation } from '@/hooks';
import { type Chat, OperationsService, SaasService } from '@/services';
import ChatService from '@/services/ChatService';
import {
  setCreditCounter,
  setKpiValidationReceipt,
  setKpiValidationTransaction,
  setUnreadChatsCounter,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';
import { cls } from '@/utils/funcs';

import { LangSelector } from '../Navbar';
import { LockedBackdrop } from './LockedBackdrop';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  locked?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  locked = false,
}) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const validatedReceiptNumber = useAppSelector(
    (store) => store.global.validatedReceiptNumber
  );
  const validatedTransactionNumber = useAppSelector(
    (store) => store.global.validatedTransactionNumber
  );
  const planInfo = useAppSelector((store) => store.global.planInfo);
  const unreadChatNumber = useAppSelector(
    (store) => store.global.unreadChatNumber
  );
  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const credits = useAppSelector((store) => store.global.credits);

  const [selectedItem, setSelectedItem] = useState('Performances');
  const location = useLocation();
  const displayKpiValidation = (kpi: any) => {
    return (kpi ?? 0) > 0 && kpi;
  };
  const navItems = useMemo(
    () => [
      {
        type: 'item',
        name: 'Performances',
        icon: (strokColor: string) => (
          <BarGroup strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/performances',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Receipts',
        icon: (strokColor: string) => (
          <ReceiptLines strokeColor={strokColor} width={16} height={16} />
        ),
        notificationCount: displayKpiValidation(validatedReceiptNumber),
        href: '/dashboard/receipts',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Transactions',
        icon: (strokColor: string) => (
          <ArrowSwitchHorizontal
            strokeColor={strokColor}
            width={16}
            height={16}
          />
        ),
        notificationCount: displayKpiValidation(validatedTransactionNumber),
        href: '/dashboard/transactions',
        disabledOnDrive: true,
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Statements',
        icon: (strokColor: string) => (
          <Bank strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/statements',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Billing',
        icon: (strokColor: string) => (
          <ReceiptLines strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/billing',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Documents',
        icon: (strokColor: string) => (
          <FolderOpen strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/documents',
        permission: 'ALL',
      },
      {
        type: 'Line',
      },
      {
        type: 'item',
        name: 'Chats',
        notificationCount: unreadChatNumber,
        icon: (strokColor: string) => (
          <MessageChat strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/chats',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Declarations',
        icon: (strokColor: string) => (
          <FileCheck strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/declarations',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Book entries',
        icon: (strokColor: string) => (
          <FileEdit strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/dashboard/accounting/book-entries',
        permission: 'ACCOUNTANT',
      },
      {
        type: 'Line',
      },
      {
        type: 'item',
        name: t('Settings'),
        icon: (strokColor: string) => (
          <Settings strokeColor={strokColor} width={16} height={16} />
        ),
        href: '/settings',
        permission: 'ALL',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );
  const navItemsMobile = useMemo(
    () => [
      {
        type: 'item',
        name: 'Performances',
        icon: (strokColor: string) => <BarGroup strokeColor={strokColor} />,
        href: '/dashboard/performances',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Receipts',
        icon: (strokColor: string) => <ReceiptLines strokeColor={strokColor} />,
        notificationCount: displayKpiValidation(validatedReceiptNumber),
        href: '/dashboard/receipts',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Transactions',
        icon: (strokColor: string) => (
          <ArrowSwitchHorizontal strokeColor={strokColor} />
        ),
        notificationCount: displayKpiValidation(validatedTransactionNumber),
        href: '/dashboard/transactions',
        disabledOnDrive: true,
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Statements',
        icon: (strokColor: string) => <Bank strokeColor={strokColor} />,
        href: '/dashboard/statements',
        permission: 'ALL',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setSelectedItem('/settings');
    } else if (location.pathname.includes('declarations')) {
      setSelectedItem('/declarations');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);
  useEffect(() => {
    OperationsService.getKpiValidation().then((res) => {
      dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
      dispatch(
        setKpiValidationTransaction(res.data?.validatedTransactionNumber)
      );
    });
    ChatService.getAllChats().then((res) => {
      dispatch(
        setUnreadChatsCounter(
          res?.data?.filter((chat: Chat) => {
            if (
              !connectedUser.roles.every((item: string) =>
                chat.readByRoles?.includes(item)
              ) &&
              chat.messages.length > 0 &&
              chat.messages[chat.messages.length - 1].who != connectedUser.id
            ) {
              return chat;
            }
          }).length
        )
      );
    });
  }, [dispatch]);

  useEffect(() => {
    SaasService.getCredits().then(({ data }) => {
      dispatch(setCreditCounter(data));
    });
  }, []);

  return (
    <>
      {locked && <LockedBackdrop />}
      <div
        className={`relative bg-white text-white h-full pt-4 px-3 transition-all duration-300 border-r border-r-[#C8CFD5] ${
          isOpen ? 'w-64' : 'w-16'
        } hidden sm:flex flex-col`}
      >
        <div className="absolute z-[10000] top-[73px] -right-3 flex justify-end mb-2">
          <button
            onClick={toggleSidebar}
            className="text-black bg-white border border-[#C8CFD5] px-[4px] py-[6px] rounded-lg"
          >
            <div
              className={`transition-transform duration-300 ${
                isOpen ? '' : 'rotate-180'
              }`}
            >
              <ChevronDouble width={16} height={16} />
            </div>
          </button>
        </div>
        {/* Navigation Links */}
        <div className="flex flex-col items-center flex-grow">
          {isOpen ? (
            <img src={UveyLogo} alt="Uvey Logo" className="w-auto h-8 mb-16	" />
          ) : (
            <img
              src={RegularUveyIcon}
              alt="Uvey Icon"
              className="w-8 h-8 mb-16"
            />
          )}
          <nav className="flex w-full flex-col gap-2">
            {navItems.map(
              (
                {
                  type,
                  name,
                  notificationCount,
                  icon,
                  href,
                  disabledOnDrive,
                  permission,
                },
                idx
              ) =>
                type === 'item' &&
                (permission === 'ALL' ||
                  connectedUser.roles.includes(permission)) ? (
                  <Tooltip
                    key={href}
                    placement="right"
                    arrow
                    title={!isOpen ? name : ''}
                  >
                    <a
                      href={href}
                      className={cls(
                        href === selectedItem && 'bg-[#E6EEFE]',
                        isOpen ? 'py-2 px-4' : 'gap-0 p-2 w-fit',
                        !planInfo?.features?.OCR &&
                          disabledOnDrive &&
                          'pointer-events-none opacity-50 select-none',
                        'flex gap-4 rounded-lg items-center cursor-pointer hover:bg-[#eef1fc] transition-colors duration-200'
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(href ?? '');
                      }}
                    >
                      <div>
                        {icon &&
                          icon(href === selectedItem ? '#0075E7' : '#303840')}
                      </div>
                      {isOpen && (
                        <div className="flex justify-between w-full gap-2 items-center truncate text-sm">
                          <p
                            className={cls(
                              href === selectedItem
                                ? 'text-[#0756F2]'
                                : 'text-[#303840]',
                              'font-normal truncate'
                            )}
                          >
                            {t(name)}
                          </p>
                          {!!notificationCount && (
                            <p className="text-xs text-[#0756F2] font-medium px-2 py-1 rounded-full">
                              {notificationCount}
                            </p>
                          )}
                        </div>
                      )}
                    </a>
                  </Tooltip>
                ) : (
                  <hr key={idx} className="my-2" />
                )
            )}
          </nav>
        </div>
        {/* Bottom Sidebar Info */}
        <div className="relative mt-6 mb-4 ">
          <div className="relative w-full rounded-xl z-50 h-fit overflow-hidden">
            {isOpen ? (
              <div className="border border-white p-3 rounded-xl mb-6 relative w-full">
                <div className="absolute bg-shape3 bg-primary bg-blur"></div>
                <div className="absolute bg-shape4 bg-secondary bg-blur"></div>
                <div className="flex items-center gap-2 mb-4">
                  <div className="bg-white rounded-full p-2">
                    <FileMultiple
                      width={16}
                      height={16}
                      strokeColor="#032363"
                    />
                  </div>
                  <div>
                    <p className="text-[#5C6B7A] text-xs">
                      {t('YOU_STILL_HAVE_AVAILABLE')}
                    </p>
                    <p className="text-[#032363] text-xl font-semibold">
                      {credits} Pages
                    </p>
                  </div>
                </div>
                <Button
                  sensitivity="Secondary"
                  label="Acheter des pages"
                  size="small"
                  icon={<Plus width={16} height={16} strokeColor="#0756F2" />}
                  cls="w-full mb-2"
                  onClick={() => {
                    navigate('/settings/ocr-management', {
                      replace: true,
                    });
                  }}
                  disabled={connectedUser.roles.includes('ACCOUNTANT')}
                />
                <Button
                  sensitivity="Cta"
                  label="Nouveau plan"
                  size="small"
                  icon={<ArrowUp width={16} height={16} strokeColor="#fff" />}
                  cls="w-full"
                  onClick={() => {
                    navigate('/settings/add-pages', {
                      replace: true,
                    });
                  }}
                  disabled={connectedUser.roles.includes('ACCOUNTANT')}
                />
              </div>
            ) : (
              <button
                onClick={toggleSidebar}
                className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full p-2 mb-6"
              >
                <FileMultiple strokeColor="#fff" />
              </button>
            )}
          </div>

          <div
            className={
              isOpen
                ? 'relative z-50 flex justify-between items-center text-center pt-4'
                : 'relative z-50 flex justify-center gap-0 items-center'
            }
          >
            <LangSelector isOpen={isOpen} />
            {isOpen && (
              <p className="text-xs text-black">{`V ${APP_VERSION}`}</p>
            )}
          </div>
          <div className="absolute bg-shape2 bg-primary bg-blur"></div>
          <div className="absolute bg-shape1 bg-secondary bg-blur"></div>
        </div>
      </div>
      {/* 796PX DISPLAY */}
      <div className="fixed bottom-0 w-full bg-white sm:hidden flex justify-around items-center  py-2 px-6 z-40 border-t border-t-[#C8CFD5]">
        {navItemsMobile.map(({ name, icon, href }) => (
          <a
            key={href}
            href={href}
            className="flex flex-col items-center text-center text-gray-700"
            onClick={(e) => {
              e.preventDefault();
              navigate(href);
            }}
          >
            {icon && icon('#0075E7')}
            <span className="text-xs">{t(name)}</span>
          </a>
        ))}
      </div>
    </>
  );
};
export default Sidebar;
