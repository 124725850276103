import type { ReactNode } from 'react';

import accountingRoutes from './accounting';
import authRoutes from './auth';
import dashboardRoutes from './dashboard';
import declarations from './declarations';
import otherRoutes from './other';
import settingsRoutes from './settings';
import wizardRoutes from './wizard';

export type RouteType = {
  element: ReactNode;
  path: string;
  children?: ReactNode;
};

const routes: RouteType[] = [
  authRoutes,
  wizardRoutes,
  dashboardRoutes,
  settingsRoutes,
  otherRoutes,
  accountingRoutes,
  declarations,
].reduce((routes, allRoutes) => [...allRoutes, ...routes], []);

export default routes;
