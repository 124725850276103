import type { FC } from 'react';
import React from 'react';

import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';

import { Button, type ButtonSensitivity } from '@/components/global';
import { useAppTranslation } from '@/hooks';

interface ConfirmationModalProps {
  /**
   * @default false
   */
  isOpen: boolean;

  /**
   * @default '''
   */
  onClose: Function;

  /**
   * @default '''
   */
  action?: Function;

  /**
   * @default '''
   */
  actionName?: string;

  /**
   * @default '''
   */
  title: string;

  /**
   * @default '''
   */
  message: string;

  /**
   * @default danger
   */
  sensitivity?: ButtonSensitivity;

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default false
   */
  disabled?: boolean;
}

/**
 *
 * @param {boolean} isOpen display and hide the modal
 * @param {Function} onClose responsible function to close the modal
 * @param {Function} action primary action
 * @param {string} title Title of the modal
 * @param {string} message message of the modal
 * @param {ButtonSensitivity} sensitivity modal sensitivity
 * @param {boolean} isLoading if the action require time to complete
 * @param {boolean} disabled disable the action
 * @returns
 */
const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen = false,
  sensitivity = 'Primary',
  disabled = false,
  isLoading = false,
  onClose,
  action,
  title,
  message,
  actionName,
}) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => {
        onClose(e);
      }}
      PaperProps={{
        style: { borderRadius: 4 },
      }}
      maxWidth="xs"
      fullWidth
    >
      <div
        className="p-4 flex flex-col gap-4 max-w-[500px] border-1 border-[#DEE2E6]"
        onClick={(e) => {
          e.cancelable = true;
          e.stopPropagation();
        }}
      >
        <div className="flex items-start justify-between">
          <div className="flex flex-col gap-4 mb-3">
            <h3 className="font-semibold text-xl">{title}</h3>
            <p className="text-[#4f5152]">{message}</p>
          </div>

          <div
            onClick={(e) => {
              onClose(e);
            }}
            className="flex justify-end cursor-pointer"
          >
            <Close fontSize="small" />
          </div>
        </div>
        <div className="flex w-full justify-end gap-3 mt-4">
          <Button
            onClick={(e) => {
              onClose(e);
            }}
            label={t('Close')}
            sensitivity="Faided"
            size="small"
          />
          {actionName && action && (
            <Button
              onClick={(e) => {
                action(e);
              }}
              label={actionName}
              isLoading={isLoading}
              disabled={disabled}
              sensitivity={sensitivity}
              size="small"
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
