import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { DashboardLayout } from '@/components/layouts';
import { AuthGuard, RolesGuard } from '@/lib/guards';
import { Role } from '@/lib/guards/RolesGuard';
import SubscriptionGuard from '@/lib/guards/SubscriptionGuard';
import { CategoryBalance } from '@/pages/dashboard/Accounting/CategoryBalance';
import { FinancialStatement } from '@/pages/dashboard/Accounting/FinancialStatement';
import { Settings } from '@/pages/dashboard/Accounting/Settings';
import DeliveryNotes from '@/pages/dashboard/Billing/DeliveryNote';
import Quotes from '@/pages/dashboard/Billing/Quote';
import DocumentSplittingPage from '@/pages/dashboard/Receipts/MultiReceiptDocumentSplitting/DocumentSplit';
import ReceiptPage from '@/pages/dashboard/Receipts/Receipt/Receipt';

import type { RouteType } from '.';

const Test = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Test')), 500);
  });
});

const Documents = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Documents')), 500);
  });
});

const Notifications = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Notifications')), 500);
  });
});

const Performances = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Performances')), 500);
  });
});

const NoPerformances = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Performances/NoPerformances')),
      500
    );
  });
});

const Receipts = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Receipts')), 500);
  });
});

const UploadReceipts = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Receipts/UploadReceipts')),
      500
    );
  });
});

const Statements = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Statements/')), 500);
  });
});

const UploadStatements = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Statements/UploadStatements')),
      500
    );
  });
});

const AddBankAccounts = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Statements/AddBankAccounts')),
      500
    );
  });
});

const Transactions = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Transactions')), 500);
  });
});

const NoTransactions = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Transactions/NoTransactions')),
      500
    );
  });
});

const CreateTransactionManual = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Transactions/TransactionManual')),
      500
    );
  });
});

const CreateTransactionCsv = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Transactions/TransactionCsv')),
      500
    );
  });
});

const ExportData = lazy(() => {
  const exportDataPath = import('@/pages/dashboard/Accounting/ExportData');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(exportDataPath), 500);
  });
});

const AccountingPlan = lazy(() => {
  const AccountingPlanPath = import(
    '@/pages/dashboard/Accounting/AccountingPlan'
  );
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountingPlanPath), 500);
  });
});

const BookEntries = lazy(() => {
  const AccountingPlanPath = import('@/pages/dashboard/Accounting/BookEntries');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountingPlanPath), 500);
  });
});

const Ledger = lazy(() => {
  const AccountingPlanPath = import('@/pages/dashboard/Accounting/Ledger');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountingPlanPath), 500);
  });
});

const AccountingPlanImport = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        // @ts-expect-error
        resolve(import('@/pages/dashboard/Accounting/AccountingPlanImport')),
      500
    );
  });
});

const BookEntriesImport = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        // @ts-expect-error
        resolve(import('@/pages/dashboard/Accounting/BookEntriesImport')),
      500
    );
  });
});

const FiscalYear = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      // @ts-expect-error
      () => resolve(import('@/pages/dashboard/Accounting/FiscalYears')),
      500
    );
  });
});

/*const CategoryBalance = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Accounting/CategoryBalance')), 500);
  });
});*/

const Chats = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Chats')), 500);
  });
});

const Declarations = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Declarations')), 500);
  });
});

const Billing = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Billing')), 500);
  });
});

const BillingTemplate = lazy(() => {
  const BillingTemplatePath = import(
    '@/pages/dashboard/Billing/BillingTemplate'
  );
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(BillingTemplatePath), 500);
  });
});

const BillingReview = lazy(() => {
  const BillingReviewPath = import('@/pages/dashboard/Billing/BillingReview');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(BillingReviewPath), 500);
  });
});

const Client = lazy(() => {
  const ClientPath = import('@/pages/dashboard/Billing/Client');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(ClientPath), 500);
  });
});

const Product = lazy(() => {
  const ProductPath = import('@/pages/dashboard/Billing/Product');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(ProductPath), 500);
  });
});

const Supplier = lazy(() => {
  const SupplierPath = import('@/pages/dashboard/Billing/supplier');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(SupplierPath), 500);
  });
});

const AccountingDashboard = lazy(() => {
  const AccountingDashboardPath = import(
    '@/pages/dashboard/Accounting/AccountingDashboard'
  );
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountingDashboardPath), 500);
  });
});

const outletElements = [
  {
    href: '',
    element: <Navigate to="performances" />,
  },
  {
    href: 'test',
    element: <Test />,
  },
  {
    href: 'documents',
    element: <Documents />,
  },
  {
    href: 'notifications',
    element: <Notifications />,
  },
  {
    href: 'performances',
    element: <Performances />,
  },
  {
    href: 'performances/no-data',
    element: <NoPerformances />,
  },
  {
    href: 'receipts',
    element: <Receipts />,
  },
  {
    href: 'receipts/upload',
    element: <UploadReceipts />,
  },
  {
    href: 'receipts/:fiscalYear/:id/idx/:index/page/:page_number',
    element: <ReceiptPage />,
  },
  {
    href: 'receipts/split',
    element: <DocumentSplittingPage />,
  },
  {
    href: 'statements',
    element: <Statements />,
  },
  {
    href: 'statements/upload',
    element: <UploadStatements />,
  },
  {
    href: 'statements/add-bank-accounts',
    element: <AddBankAccounts />,
  },
  {
    href: 'transactions',
    element: <Transactions />,
  },
  {
    href: 'transactions/create-transaction-manual',
    element: <CreateTransactionManual />,
  },
  {
    href: 'transactions/create-transaction-csv',
    element: <CreateTransactionCsv />,
  },

  {
    href: 'transactions/no-data',
    element: <NoTransactions />,
  },
  {
    href: 'accounting/settings-accountant/export-data',
    element: <ExportData />,
  },
  {
    href: 'accounting/accounting-plan',
    element: <AccountingPlan />,
  },
  {
    href: 'accounting/accounting-plan-import',
    element: <AccountingPlanImport />,
  },
  {
    href: 'accounting/bookentries-import',
    element: <BookEntriesImport />,
  },
  {
    href: 'accounting/book-entries',
    element: <BookEntries />,
  },
  {
    href: 'accounting/settings-accountant/fiscal-year',
    element: <FiscalYear />,
  },
  {
    href: 'accounting/ledger',
    element: <Ledger />,
  },
  {
    href: 'accounting/category-balance',
    element: <CategoryBalance />,
  },
  {
    href: 'accounting/financial-statement',
    element: <FinancialStatement />,
  },
  {
    href: 'accounting/settings-accountant',
    element: <Settings />,
  },
  {
    href: 'declarations',
    element: <Declarations />,
  },
  {
    href: 'chats',
    element: <Chats />,
  },

  {
    href: 'billing',
    element: <Billing />,
  },
  {
    href: 'billing/quotes',
    element: <Quotes />,
  },
  {
    href: 'billing/delivery-notes',
    element: <DeliveryNotes />,
  },
  {
    href: 'billing/suppliers',
    element: <Supplier />,
  },

  {
    href: 'billing/invoices/create/template',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/quotes/create/template',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/delivery-notes/create/template',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/update/template/:RECEIPT_ID',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/duplicate/template/:RECEIPT_ID',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/delivery-notes/update/template/:RECEIPT_ID',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/quotes/update/template/:RECEIPT_ID',
    element: <BillingTemplate />,
  },
  {
    href: 'billing/:id/review',
    element: <BillingReview />,
  },

  {
    href: 'billing/quotes/:id/review',
    element: <BillingReview />,
  },

  {
    href: 'billing/delivery-notes/:id/review',
    element: <BillingReview />,
  },

  {
    href: 'billing/client',
    element: <Client />,
  },

  {
    href: 'billing/Product',
    element: <Product />,
  },

  {
    href: 'accounting/api-pro',
    element: <AccountingDashboard />,
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        <RolesGuard allowedRoles={[Role.OWNER, Role.USER, Role.ACCOUNTANT]}>
          <SubscriptionGuard>
            <DashboardLayout />
          </SubscriptionGuard>
        </RolesGuard>
      </AuthGuard>
    ),
    path: 'dashboard',
  },
];

export default routes;
