import type { ComponentProps, FC, ReactNode } from 'react';

import type { ButtonSensitivity, ButtonSize, IconPosition } from '..';
import { LoadingSpinner, getCls } from '..';

const DEFAULT_CLS_SMALL =
  'text-xs rounded-lg h-10 px-4 py-2.5 flex items-center justify-center';

const DEFAULT_CLS_MEDIUM =
  'text-sm rounded-lg h-12 px-4 py-3 flex items-center justify-center';

const DEFAULT_CLS_BIG =
  'text-base rounded-lg h-[52px] px-6 py-3.5 flex items-center justify-center';

const DEFAULT_LABEL_CLS_PRIMARY = 'font-semibold px-1 text-white';

const DEFAULT_LABEL_CLS_SECONDARY = 'font-semibold px-1 text-[#0756F2]';

const DEFAULT_LABEL_CLS_TERTIARY = 'font-semibold px-1 text-[#060708] ';

const DEFAULT_LABEL_CLS_DESTRUCTIVE = 'font-semibold px-1 text-white ';
const DEFAULT_LABEL_CLS_CTA = 'font-semibold px-1 text-white ';

const DEFAULT_LABEL_CLS_FAIDED = 'font-bold px-1 text-[#1B262C]';

interface ButtonProps extends ComponentProps<'button'> {
  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  label?: string | ReactNode /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default null
   */
  loadingSpinnerProps?: ComponentProps<typeof LoadingSpinner>;

  /**
   * @default 'medium''
   */
  size?: ButtonSize;

  /**
   * @default 'primary''
   */
  sensitivity?: ButtonSensitivity;

  // children?: string | ReactNode;
}

/**
 * Global Button Component
 * @param {string} label label text
 * @param {string} cls button class
 * @param {string} labelCls label class
 * @param {boolean} applyDefaultCls whether to apply the default button class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {boolean} isLoading whether to show the loading spinner
 * @param {any[]} ...props list of props to pass to the native button element
 * @example <Button label="Submit" icon={<CheckCircleIcon />} cls="hover:bg-gray-200 transition duration-150 ease-out hover:ease-in" isLoading={isLoading} loadingSpinnerProps={{ strokeColor: 'lightgrey' }} />
 */
const Button: FC<ButtonProps> = ({
  cls = '',
  label = '',
  labelCls = '',
  applyDefaultCls = true,
  applyDefaultLabelCls = true,
  icon = null,
  iconPosition = 'left',
  isLoading = false,
  loadingSpinnerProps = null,
  size = 'medium',
  sensitivity = 'Primary',
  ...props
}) => {
  const getButtonSizeCLS = (size: ButtonSize) => {
    const sizeCLS = {
      big: DEFAULT_CLS_BIG,
      small: DEFAULT_CLS_SMALL,
      medium: DEFAULT_CLS_MEDIUM,
    };

    return sizeCLS[size];
  };

  const getButtonLabelCLS = (sensitivity: ButtonSensitivity) => {
    const labelCLS = {
      Primary: DEFAULT_LABEL_CLS_PRIMARY,
      Secondary: DEFAULT_LABEL_CLS_SECONDARY,
      Tertiary: DEFAULT_LABEL_CLS_TERTIARY,
      Destructive: DEFAULT_LABEL_CLS_DESTRUCTIVE,
      Cta: DEFAULT_LABEL_CLS_CTA,
      Faided: DEFAULT_LABEL_CLS_FAIDED,
    };

    return labelCLS[sensitivity];
  };

  const getButtonSensitivityCLS = (sensitivity: ButtonSensitivity) => {
    const buttonCLS = {
      Primary:
        'bg-[#0756F2] hover:bg-[#6597FB] focus:bg-[#0756F2] focus:outline-none focus:ring focus:ring-[#6597FB] active:bg-[#043390]',
      Secondary:
        'rounded-[8px] border-[1px] border-[#0756F2] hover:bg-[#E6EEFE] focus:outline-none focus:ring focus:ring-[#043390] active:bg-[#E6EEFE]',
      Tertiary:
        'rounded-[8px] border-[1px] border-[#C8CFD5] focus:outline-none focus:ring focus:ring-[#303840] active:border-[#C8CFD5]',
      Destructive: 'bg-[#DF1B41]',
      Cta: 'rounded-[8px] bg-gradient-to-r from-cyan-500 to-blue-500 focus:outline-none focus:ring focus:ring-[#303840] active:border-[#C8CFD5]',
      Faided: 'bg-[#FFFFFF] border border-[#D5DBE1]',
    };

    return buttonCLS[sensitivity];
  };

  cls = getCls(applyDefaultCls, getButtonSizeCLS(size), cls);

  labelCls = getCls(
    applyDefaultLabelCls,
    getButtonLabelCLS(sensitivity),
    labelCls
  );

  return (
    <button
      {...props}
      className={`${cls} ${getButtonSensitivityCLS(
        sensitivity
      )} disabled:opacity-50 disabled: hover:opacity-90 transition-opacity duration-200`}
    >
      <>
        {isLoading && <LoadingSpinner width="15" {...loadingSpinnerProps} />}
        {iconPosition === 'left' && icon}
        {label && typeof label === 'string' ? (
          <span className={labelCls}>{label}</span>
        ) : (
          label
        )}

        {iconPosition === 'right' && icon}
      </>
    </button>
  );
};

export default Button;
