import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import {
  ContactlessOutlined,
  ReceiptLongOutlined,
  TaskOutlined,
} from '@mui/icons-material';
import { Popover } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Bell } from '@/assets/icons';
import { userAvatar } from '@/assets/images';
import { Button, Image } from '@/components/global';
import { useAppSelector, useAppTranslation } from '@/hooks';
import { useWebSocket } from '@/hooks/websocket-hook';
import { appToast } from '@/lib/ToastContainers';
import {
  type ChatNotification,
  DeclarationService,
  type DeclarationSubmit,
  type IDeclaration,
  type Member,
  NotificationsService,
  OperationsService,
  type Receipt,
  SaasService,
  type Transaction,
} from '@/services';
import {
  type ChatMessageNotification,
  NotificationType,
  type WebSocketNotification,
} from '@/store/reducers';

interface NotificationProps {
  chatNotification?: ChatNotification[];
}

const Notification: FC<NotificationProps> = ({
  chatNotification: initialNotificationsList,
}) => {
  const { t } = useAppTranslation();
  const [Members, setMembers] = useState<Member[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [newNotifications, setNewNotifications] = useState(false);
  const [allNotifications, setAllNotifications] = useState<ChatNotification[]>(
    initialNotificationsList || []
  );
  const [readNotificationsConstraint, setReadNotificationsConstraint] =
    useState(false);
  const navigate = useNavigate();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const [socket, connected] = useWebSocket('CHAT');

  if (connected && socket) {
    socket.onmessage = (event: any) => {
      try {
        const notification = JSON.parse(event.data) as WebSocketNotification;
        if (
          notification &&
          notification?.type === NotificationType.NEW_CHAT_MESSAGE_NOTIFICATION
        ) {
          const newNotification =
            notification?.corpus as ChatMessageNotification;
          if (newNotification?.senderId != connectedUser.id) {
            getChatNotifications();
            setNewNotifications(true);
          }
        }
      } catch (e) {
        appToast.error(t('Something went wrong'));
      }
    };
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setNewNotifications(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
    readNotifications();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await SaasService.getMembersByCompanyId();
      getChatNotifications();
      setMembers(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isPopoverOpen) {
      setNewNotifications(false);
    }
  }, [isPopoverOpen]);

  const getChatNotifications = async () => {
    const responseN = await NotificationsService.getChatNotifications();
    const chatsWithLinkedObjects = await Promise.all(
      responseN?.data?.map(async (chatNotification: ChatNotification) => {
        let linkedObject: Receipt | Transaction | IDeclaration | null = null;
        try {
          if (chatNotification.objectType === 'TRANSACTION') {
            const transactionResponse = await OperationsService.getTransaction(
              chatNotification.objectId
            );
            linkedObject = transactionResponse.data;
          } else if (chatNotification.objectType === 'RECEIPT') {
            const receiptResponse = await OperationsService.getReceipt(
              chatNotification.objectId
            );
            linkedObject = receiptResponse.data;
          } else if (
            chatNotification.objectType === 'DECLARATION' &&
            chatNotification.objectId.substring(
              chatNotification.objectId.indexOf('_') + 1,
              chatNotification.objectId.length
            ) === connectedUser.companyId
          ) {
            const declarationResponse =
              await DeclarationService.getDeclarationTemplateById(
                chatNotification.objectId.substring(
                  0,
                  chatNotification.objectId.indexOf('_')
                )
              );
            linkedObject = declarationResponse.data;
            const declarationsSubmitByYear =
              await DeclarationService.getDeclarationSubmitsByYear(
                declarationResponse.data.dueDate.substring(0, 4)
              );

            const templateAssociatedDeclaration =
              declarationsSubmitByYear?.data instanceof Array
                ? declarationsSubmitByYear.data.filter(
                    (declarationSubmit: DeclarationSubmit) => {
                      return (
                        declarationResponse?.data?.id ===
                        declarationSubmit.template?.id
                      );
                    }
                  )
                : [];

            (linkedObject as IDeclaration).declarationSubmit =
              templateAssociatedDeclaration.length > 0
                ? templateAssociatedDeclaration[0]
                : undefined;
          }
        } catch (error) {
          linkedObject = null;
        }
        return {
          ...chatNotification,
          linkedObject,
        };
      })
    );
    const filteredChats = chatsWithLinkedObjects.filter(
      (chatNotification: ChatNotification) =>
        chatNotification?.senderId != connectedUser.id
    );
    setAllNotifications(filteredChats.reverse() as ChatNotification[]);
  };

  const getUserById = (userId: string) => {
    const user = Members.find((member) => member.user.id === userId);
    return user ? user.user : undefined;
  };

  const readNotifications = async () => {
    await NotificationsService.readChatNotifications();
    setAllNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({
        ...notification,
        readByRoles: connectedUser.roles,
      }))
    );
  };
  useEffect(() => {
    const allNotificationsContainsRole = allNotifications.some((notification) =>
      connectedUser.roles.every(
        (userRole: string) =>
          !notification.readByRoles ||
          !notification.readByRoles.includes(userRole)
      )
    );
    setReadNotificationsConstraint(allNotificationsContainsRole);
  }, [allNotifications]);

  return (
    <div className="flex rounded-full">
      <div className="indicator">
        {(newNotifications || readNotificationsConstraint) && (
          <span className="indicator-item badge bg-[#0075E7] text-white">
            new
          </span>
        )}
        <div
          onClick={(e) => {
            e.cancelable = true;
            e.stopPropagation();
            handleClick(e);
          }}
          className={`flex items-center justify-center p-1 cursor-pointer h-8	w-8 rounded-lg  border border-[#C8CFD5]`}
        >
          <div className="relative flex rounded-full">
            <Bell width={16} height={16} strokeColor="#303840" />
          </div>
        </div>
      </div>

      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.cancelable = true;
            e.stopPropagation();
            handleClose();
          }}
          onClick={(e) => {
            e.cancelable = true;
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPopover-paper': {
              marginLeft: '8px',
              width: '100%',
              maxWidth: 500,
              borderRadius: '15px',
            },
          }}
        >
          <div className="min-h-[450px] max-h-[550px] flex flex-col ">
            <div className="px-4 flex justify-between items-center w-full" />
            <div className="flex flex-row w-full justify-center items-center">
              <h2 className="pl-3 m-2 font-bold">Notifications</h2>
              <Button
                label={'Go to chats'}
                cls="rounded-md bg-transparent text-sm whitespace-nowrap font-bold text-[#0075E7] hover:opacity-80 hover:bg-[#F8FBFF] transition-opacity duration-200 ml-auto mr-3 w-[20%] h-[7%]"
                onClick={() => navigate(`/dashboard/Chats`)}
              />
            </div>
            <hr className="w-full" />
            <div className="min-h-[450px] max-h-[550px] overflow-y-scroll py-0.5 flex-col w-full">
              {allNotifications?.map((el) => (
                <div
                  className={`w-full flex flex-col  ${
                    !connectedUser.roles.some((item: string) =>
                      el.readByRoles?.includes(item)
                    ) && 'bg-[#F8FBFF]'
                  } `}
                >
                  <div className="w-full h-[100px] flex flex-row inline-flex gap-3.5 overflow-hidden">
                    <div className="pl-7 w-full h-full gap-3.5 flex-row flex items-center ">
                      <div className="flex items-center justify-center w-9 h-9 rounded-full border-2 border-[#0075E7]">
                        {el.objectType === 'RECEIPT' ? (
                          <ReceiptLongOutlined htmlColor="#0075E7" />
                        ) : el.objectType === 'TRANSACTION' ? (
                          <ContactlessOutlined htmlColor="#0075E7" />
                        ) : (
                          <TaskOutlined htmlColor="#0075E7" />
                        )}
                      </div>
                      <div className="flex flex-col py-2 overflow-hidden overflow-ellipsis w-4/6">
                        <div className="text-medium font-normal break-all">
                          <p>
                            {t('YouHaveBeenTaggedOnChat')}
                            {(el.linkedObject as Receipt)?.fileName ||
                              (el.linkedObject as Transaction)?.annotation ||
                              (el.linkedObject as IDeclaration)?.name +
                                ' ' +
                                (el.linkedObject as IDeclaration)?.dueDate ||
                              ''}
                          </p>
                        </div>
                        <div className="flex flex-row truncate mt-0.5 space-x-1">
                          <Image
                            cls="w-4 h-4 relative rounded-full "
                            url={getUserById(el.senderId)?.avatar || userAvatar}
                            alt="user"
                          />
                          <div className="text-sm font-semibold truncate w-5/6">
                            {getUserById(el.senderId)?.name +
                              ' ' +
                              getUserById(el.senderId)?.surname}{' '}
                            : {el.message}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center text-zinc-500 justify-center text-[11px] ml-auto pr-3 ">
                        {moment(el.sentAt).format('DD MMM')}
                      </div>
                    </div>
                  </div>
                  <hr className="w-full" />
                </div>
              ))}
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Notification;
