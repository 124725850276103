import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const FileMultiple: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5202 6.23999V9.12C11.5202 9.65019 11.95 10.08 12.4802 10.08H15.3602M17.2802 2.39999H10.56C9.49958 2.39999 8.63997 3.2596 8.63996 4.31998V6.23999M17.2802 2.39999H17.8157C18.0861 2.39999 18.3434 2.51386 18.5297 2.70982C18.8096 3.0043 19.2529 3.45771 19.6802 3.83999C20.015 4.13961 20.5411 4.67731 20.8562 5.0041C21.0265 5.18082 21.1202 5.41679 21.1202 5.66226L21.1202 6.23999M17.2802 2.39999V5.27999C17.2802 5.81019 17.71 6.23999 18.2402 6.23999H21.1202M21.1202 6.23999L21.1199 15.84C21.1199 16.9004 20.2603 17.76 19.1999 17.76H15.3599M13.9202 7.67999C13.4929 7.29771 13.0496 6.8443 12.7697 6.54982C12.5834 6.35386 12.3261 6.23999 12.0557 6.23999H4.79996C3.73958 6.23999 2.87996 7.0996 2.87996 8.15998L2.87988 19.6799C2.87988 20.7403 3.73949 21.6 4.79988 21.6L13.4399 21.6C14.5003 21.6 15.3599 20.7404 15.3599 19.68L15.3602 9.50226C15.3602 9.25679 15.2665 9.02082 15.0962 8.8441C14.7811 8.51731 14.255 7.97961 13.9202 7.67999Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileMultiple;
