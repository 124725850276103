import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const File: FC<IconProps> = ({ width = 155, height = 155 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 155 155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M77.5 155C120.302 155 155 120.302 155 77.5C155 34.6979 120.302 0 77.5 0C34.6979 0 0 34.6979 0 77.5C0 120.302 34.6979 155 77.5 155Z"
          fill="#E6EEFE"
        />
        <path
          d="M115.281 42.3021V124.323C115.281 126.906 113.021 129.167 110.438 129.167H44.5625C41.9792 129.167 39.7188 126.906 39.7188 124.323V30.3542C39.7188 27.7709 41.9792 25.5104 44.5625 25.5104H98.4896L115.281 42.3021Z"
          fill="white"
        />
        <path
          d="M115.282 42.3021H103.334C100.751 42.3021 98.4902 40.0417 98.4902 37.4584V25.5104L115.282 42.3021Z"
          fill="white"
        />
        <path
          d="M110.436 131.104H88.4779C87.5091 131.104 86.8633 130.458 86.8633 129.49C86.8633 128.521 87.5091 127.875 88.4779 127.875H110.436C112.374 127.875 113.665 126.26 113.665 124.646V42.9479L97.5195 26.8021H79.7591C78.7904 26.8021 78.1445 26.1563 78.1445 25.1875C78.1445 24.2188 78.7904 23.5729 79.7591 23.5729H98.1654C98.4883 23.5729 99.1341 23.8959 99.457 23.8959L116.249 40.6875C116.572 41.0104 116.572 41.3334 116.572 41.9792V124C116.895 128.198 113.988 131.104 110.436 131.104Z"
          fill="#0756F2"
        />
        <path
          d="M39.7181 72.9792C38.7493 72.9792 38.1035 72.3334 38.1035 71.3646V30.3542C38.1035 28.7396 38.7493 27.125 40.041 25.8334C40.6868 25.1875 41.0098 24.8646 41.6556 24.5417C42.6243 24.2188 43.5931 23.8959 44.5618 23.8959H71.041C72.0098 23.8959 72.6556 24.5417 72.6556 25.5105C72.6556 26.4792 71.6868 27.125 71.041 27.125H44.5618C43.916 27.125 43.5931 27.125 42.9473 27.448C42.6243 27.448 42.3014 27.7709 41.9785 28.0938C41.3327 28.7396 41.0098 29.7084 41.0098 30.3542V71.3646C41.3327 72.0105 40.3639 72.9792 39.7181 72.9792Z"
          fill="#0756F2"
        />
        <path
          d="M78.791 131.104H44.5618C41.0098 131.104 38.1035 128.198 38.1035 124.646V79.4375C38.1035 78.4688 38.7493 77.8229 39.7181 77.8229C40.6868 77.8229 41.3327 78.4688 41.3327 79.4375V124.646C41.3327 126.583 42.9473 127.875 44.5618 127.875H78.791C79.7598 127.875 80.4056 128.521 80.4056 129.49C80.4056 130.458 79.7598 131.104 78.791 131.104Z"
          fill="#0756F2"
        />
        <path
          d="M115.281 43.9167H103.333C99.7812 43.9167 96.875 41.0104 96.875 37.4583V25.5104C96.875 24.8646 97.1979 24.2188 97.8437 23.8958C98.4896 23.5729 99.1354 23.8958 99.4583 24.2188L116.25 41.0104C116.573 41.3333 116.896 42.3021 116.573 42.625C116.573 43.5938 115.927 43.9167 115.281 43.9167ZM100.104 29.3854V37.4583C100.104 39.3958 101.719 40.6875 103.333 40.6875H111.406L100.104 29.3854Z"
          fill="#0756F2"
        />
        <path
          d="M83.6341 61.3542H56.5091C55.5404 61.3542 54.8945 60.7083 54.8945 59.7396C54.8945 58.7708 55.5404 58.125 56.5091 58.125H83.6341C84.6029 58.125 85.2487 58.7708 85.2487 59.7396C85.2487 60.7083 84.6029 61.3542 83.6341 61.3542Z"
          fill="#0756F2"
        />
        <path
          d="M98.4883 73.9479H56.5091C55.5404 73.9479 54.8945 73.3021 54.8945 72.3333C54.8945 71.3646 55.5404 70.7188 56.5091 70.7188H98.4883C99.457 70.7188 100.103 71.3646 100.103 72.3333C100.103 73.3021 99.1341 73.9479 98.4883 73.9479Z"
          fill="#0756F2"
        />
        <path
          d="M98.4883 86.2187H56.5091C55.5404 86.2187 54.8945 85.5729 54.8945 84.6041C54.8945 83.6354 55.5404 82.9896 56.5091 82.9896H98.4883C99.457 82.9896 100.103 83.6354 100.103 84.6041C100.103 85.5729 99.1341 86.2187 98.4883 86.2187Z"
          fill="#0756F2"
        />
        <path
          d="M98.4883 98.8125H56.5091C55.5404 98.8125 54.8945 98.1666 54.8945 97.1979C54.8945 96.2291 55.5404 95.5833 56.5091 95.5833H98.4883C99.457 95.5833 100.103 96.2291 100.103 97.1979C100.103 98.1666 99.1341 98.8125 98.4883 98.8125Z"
          fill="#0756F2"
        />
        <path
          d="M115.281 145.635C124.198 145.635 131.426 138.407 131.426 129.49C131.426 120.572 124.198 113.344 115.281 113.344C106.364 113.344 99.1348 120.572 99.1348 129.49C99.1348 138.407 106.364 145.635 115.281 145.635Z"
          fill="#0756F2"
        />
        <path
          d="M115.604 137.563C111.083 137.563 107.531 134.01 107.531 129.49C107.531 124.969 111.083 121.417 115.604 121.417C118.51 121.417 121.094 123.031 122.708 125.615C123.031 125.938 122.708 126.583 122.385 126.583C122.063 126.906 121.417 126.583 121.417 126.26C120.125 124.323 118.188 123.031 115.604 123.031C112.052 123.031 109.146 125.938 109.146 129.49C109.146 133.042 112.052 135.948 115.604 135.948C118.833 135.948 121.74 133.365 122.062 130.135L121.417 130.781C121.094 131.104 120.448 131.104 120.125 130.781C119.802 130.458 119.802 129.813 120.125 129.49L122.062 127.552C122.385 127.229 123.031 127.229 123.354 127.552L125.292 129.49C125.615 129.813 125.615 130.458 125.292 130.781C124.969 131.104 124.323 131.104 124 130.781L123.677 130.458C123.354 134.656 119.802 137.563 115.604 137.563ZM118.51 132.396L115.604 131.427C115.281 131.427 114.958 131.104 114.958 130.781V126.906C114.958 126.583 115.281 126.26 115.604 126.26C115.927 126.26 116.25 126.583 116.25 126.906V130.135L118.51 130.781C118.833 130.781 119.156 131.427 119.156 131.75C119.156 132.073 118.833 132.396 118.51 132.396Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default File;
