import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const AddFile: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7836 15.878C21.3079 14.6921 21.599 13.3801 21.599 12C21.599 6.69809 17.301 2.40002 11.999 2.40002C6.69711 2.40002 2.39905 6.69809 2.39905 12C2.39905 17.302 6.69711 21.6 11.999 21.6C13.706 21.6 15.309 21.1545 16.6979 20.3734L21.6009 21.5991L20.7836 15.878Z"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AddFile;
