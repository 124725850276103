import { type FC, useEffect, useState } from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { type FiscalYear, OperationsService } from '@/services';
import { setFiscalYear } from '@/store/actions/action-creators';

const FiscalYearSelector: FC = () => {
  const dispatch = useAppDispatch();
  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const [fiscalYearValue, setFiscalYearValue] = useState<number>(fiscalYear);
  const [years, setYears] = useState<FiscalYear[]>([]);

  const setDefaultYear = (data: FiscalYear[], year: number) => {
    if (
      !data.find(
        (e) =>
          e.fiscalYear === year ||
          e.fiscalYear === Number(localStorage.getItem('fiscalYear'))
      )
    ) {
      setFiscalYearValue(data[0].fiscalYear);
      dispatch(setFiscalYear(data[0].fiscalYear));
      localStorage.setItem('fiscalYear', data[0].fiscalYear.toString());
    }
  };

  const getFiscalYears = () => {
    const yearsFound = localStorage.getItem('fiscalYears')
      ? JSON.parse(localStorage.getItem('fiscalYears') ?? '')
      : '';
    if (yearsFound === '') {
      OperationsService.getFiscalYears().then((res) => {
        localStorage.setItem('fiscalYears', JSON.stringify(res.data));
        const data: FiscalYear[] = res.data.sort(
          (a: FiscalYear, b: FiscalYear) => b.fiscalYear - a.fiscalYear
        );
        setYears(data);
        setDefaultYear(data, fiscalYear);
      });
    } else {
      const data: FiscalYear[] = yearsFound.sort(
        (a: FiscalYear, b: FiscalYear) => b.fiscalYear - a.fiscalYear
      );
      setYears(data);
      setDefaultYear(data, fiscalYear);
    }
  };

  useEffect(() => {
    getFiscalYears();
  }, [fiscalYear]);
  return (
    <div>
      <FormControl fullWidth>
        <Select
          labelId="fiscal-year-select"
          id="fiscal-year-select"
          value={fiscalYearValue}
          sx={{
            borderRadius: 2,
            height: 37,
            borderWidth: 1,
            outline: 'none',
          }}
          onChange={(e) => {
            setFiscalYearValue(e.target.value as number);
            dispatch(setFiscalYear(e.target.value as number));
            localStorage.setItem('fiscalYear', e.target.value as string);
          }}
          onFocus={getFiscalYears}
        >
          {years.map((year) => (
            <MenuItem key={year.fiscalYear} value={year.fiscalYear}>
              {year.fiscalYear}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default FiscalYearSelector;
