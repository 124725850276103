import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const FileCheck: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2999 21.6H5.49984C4.17436 21.6 3.09984 20.5254 3.09985 19.2L3.09995 4.80001C3.09995 3.47453 4.17447 2.40002 5.49995 2.40002H16.3002C17.6257 2.40002 18.7002 3.47454 18.7002 4.80002V11.4M13.9002 18.2L16.1002 20.4L20.9002 15.5998M7.30023 7.20002H14.5002M7.30023 10.8H14.5002M7.30023 14.4H10.9002"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileCheck;
