import React, { type FC, useEffect, useRef, useState } from 'react';

import { Plus } from '@/assets/icons';

interface AccordionItemProps {
  title: string;
  amount1: string;
  amount2: string;
  content: string;
  isOpen: boolean;
  onToggle: () => void;
}

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  amount1,
  amount2,
  content,
  isOpen,
  onToggle,
}) => {
  const [isRotated, setIsRotated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [content]);

  const handleClick = () => setIsRotated((prev) => !prev);

  return (
    <div className="mb-2">
      <button
        onClick={() => {
          onToggle();
          handleClick();
        }}
        className="flex justify-between items-center w-full p-2.5 text-left bg-[#E2E6E9] rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
      >
        <div className="flex flex-col items-start mr-4">
          <span className="text-xs text-gray-500">Label</span>
          <span className="font-semibold text-sm">{title}</span>
        </div>
        <div className="flex gap-12 items-center">
          <div className="flex flex-col items-start mr-4">
            <span className="text-xs text-gray-500">2023</span>
            <span className="font-semibold text-sm">{amount1}</span>
          </div>
          <div className="flex flex-col items-start mr-4">
            <span className="text-xs text-gray-500">2022</span>
            <span className="font-semibold text-sm">{amount2}</span>
          </div>
          <span
            className={`transition-transform duration-300 ${
              isRotated ? 'rotate-[-135deg]' : ''
            }`}
          >
            <Plus width={16} height={16} strokeColor="#000" />
          </span>
        </div>
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? 'max-h-[1000px]' : 'max-h-0'
        }`}
        style={{ height: isOpen ? contentHeight : 0 }}
      >
        <div className="accountant p-4 rounded-xl border border-[#C8CFD5] rounded-lg mt-2">
          <h3 className="text-sm font-semibold mb-2">{content}</h3>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="w-[339px]">Label</th>
                <th className="w-[122px]">Note</th>
                <th className="w-[122px]">2023</th>
                <th className="w-[122px]">2022</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface AccordionProps {
  items: { title: string; amount1: string; amount2: string; content: string }[];
}

const Accordion: FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          amount1={item.amount1}
          amount2={item.amount2}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
