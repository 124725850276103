import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

interface TabsProps {
  selected: string;
}

const Tabs: FC<TabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const SECTIONS = [
    { name: 'Balance sheet', href: 'balance-sheet', permission: 'ACCOUNTANT' },
    {
      name: 'Income statement',
      href: 'income-statement',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'Cash flow statement',
      href: 'cash-flow-statement',
      permission: 'ACCOUNTANT',
    },
  ] as const;
  return (
    <div className="border-b border-b-[#C8CFD5] -translate-y-4 z-10 w-full">
      <div className="flex">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ACCOUNTANT' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'text-sm font-normal px-4 py-4 w-full text-center first:rounded-tl-lg last:rounded-tr-lg',
                  name === selected
                    ? 'text-[#0756F2] border-b-[3px] border-[#0756F2] z-20'
                    : 'text-[#5C6B7A] cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/accounting/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Tabs;
